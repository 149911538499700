<template>
  <Header />
  <div class="page">
    <slot />
  </div>
  <RenderCacheable
    :max-age="86400"
    :cache-key="[getUserLanguage.name, getHash].join('--')"
  >
    <Footer />
  </RenderCacheable>
</template>

<script setup>
import Footer from "~~/modules/footer/components/Footer.vue";
import Header from "~~/modules/header/components/Header.vue";
import { useStorageStore } from "~/store/storageStore";
import { useLanguageStore } from "~/store/languageStore";
import { useCategoriesStore } from "~/store/categoriesStore";

const storageStore = useStorageStore();
const languageStore = useLanguageStore();
const { getUserLanguage } = storeToRefs(languageStore);
const categoryStore = useCategoriesStore();
const { getHash } = storeToRefs(categoryStore);

onMounted(() => {
  // для очистки стора с 09.10.24
  if (storageStore.storage.subscriptionViewState) {
    storageStore.removeStorageState("subscriptionViewState");
  }
});
</script>
