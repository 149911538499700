<template>
  <div class="languages" :class="{ modalMenu }">
    <div
      v-for="language in languageConfig"
      :key="language"
      class="languages__lang"
      :class="{ active: language.synonym === getUserLanguage.synonym }"
      @click="changeLang(language)"
    >
      <img
        v-if="modalMenu && language.synonym === 'ua'"
        src="/img/flag-ua.svg"
        alt="ua language"
      />
      <span>
        {{ languagesView[language.name] }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { useLanguageStore } from "~/store/languageStore";
import { languageConfig } from "~/config/language.config";

const props = defineProps({
  modalMenu: { type: Boolean, required: false, default: false },
});

const languageStore = useLanguageStore();
const { getUserLanguage } = storeToRefs(languageStore);

const languagesView = {
  ["uk"]: "Укр",
  ["ru"]: "Рус",
};

const changeLang = (lang) => {
  languageStore.changeLanguage(lang);
};
</script>

<style lang="scss" scoped>
.languages {
  @include flex-container(row, center);

  &:not(.modalMenu) {
    @include bigMobile {
      display: none;
    }

    .languages__lang {
      padding: 8px;

      &:first-child {
        border-right: 1px solid white;
      }

      &.active {
        color: var(--color-primary-base);
      }
    }
  }

  &.modalMenu {
    gap: 24px;

    & .languages__lang {
      border: 1px solid var(--color-grey-110);
      border-radius: 16px;

      padding: 8px 16px;

      &.active {
        border-color: var(--color-primary-base);
      }
    }
  }

  &__lang {
    @include flex-container(row, center, center);
    gap: 8px;

    @include font(12, 14);
    color: white;

    cursor: pointer;
  }
}
</style>
