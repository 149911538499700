<template>
  <div
    class="modal-menu"
    :class="[{ active: menuItems.active }, { mobileCatalog }]"
    @click.stop="closeModal"
  >
    <div class="modal-menu__wrapper" @click.stop>
      <section class="modal-menu__content">
        <header class="modal-menu__header">
          <div class="modal-menu__header-block">
            <Logo @click.stop="closeModal" />
            <ButtonLanguage :modal-menu="true" />
            <button class="modal-menu__close" @click="closeModal">
              <img src="~~/assets/icons/cross-white.svg" alt="close button" />
            </button>
          </div>
          <div class="modal-menu__header-block">
            <div
              v-if="null === userStore.currentUser"
              class="modal-menu__account"
            >
              <img
                src="~~/assets/icons/account-header.svg"
                alt="account"
                class="modal-menu__account-img"
              />
              <div class="modal-menu__account-buttons">
                <div class="modal-menu__account-btn" @click="signIn">
                  {{ _T("@Log in") }}
                </div>
                <div class="modal-menu__account-buttons-line"></div>
                <div class="modal-menu__account-btn" @click="signUp">
                  {{ _T("@Registration") }}
                </div>
              </div>
            </div>
            <MyLink
              v-else
              name="account_profile"
              class="modal-menu__account"
              @click.stop="closeModal"
            >
              <div class="modal-menu__account-img-w">
                <img
                  src="~~/assets/icons/account-logo.svg"
                  alt="account logo"
                />
              </div>
              <p class="modal-menu__account-title">
                {{ userStore.userFullName }}
              </p>
            </MyLink>
          </div>
        </header>
        <main class="modal-menu__main">
          <div class="modal-menu__main-wrapper">
            <section class="modal-menu__section">
              <ModalMenuItem
                class="modal-menu__item mobileCatalog"
                :is-button="true"
                @click="openCatalog"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Goods catalog")
                  }}</span>
                </template>
                <template #image>
                  <img
                    src="~~/assets/icons/catalog-black.svg"
                    :alt="_T('@Goods catalog')"
                  />
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                class="modal-menu__item"
                :is-button="true"
                @click="showCart"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Cart")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5 22C20.3284 22 21 21.3284 21 20.5C21 19.6716 20.3284 19 19.5 19C18.6716 19 18 19.6716 18 20.5C18 21.3284 18.6716 22 19.5 22Z"
                        fill="#2B2B2B"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 22C10.3284 22 11 21.3284 11 20.5C11 19.6716 10.3284 19 9.5 19C8.67157 19 8 19.6716 8 20.5C8 21.3284 8.67157 22 9.5 22Z"
                        fill="#2B2B2B"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 4H22L20 15H7L5 4ZM5 4C4.83333 3.33333 4 2 2 2"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 15H7H5.23077C3.44646 15 2.5 15.7812 2.5 17C2.5 18.2188 3.44646 19 5.23077 19H19.5"
                        stroke="#2B2B2B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div v-if="cartQuantity > 0" class="cart-number">
                      <span class="cart-count">{{ cartQuantity }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                v-if="null !== userStore.currentUser"
                class="modal-menu__item"
                :route-name="'account_favorites'"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Favorites")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="/img/account-favorite.svg"
                      alt="favorites button"
                    />
                    <div v-if="favoritesProducts > 0" class="cart-number">
                      <span class="cart-count">{{ favoritesProducts }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>
              <ModalMenuItem
                v-else
                class="modal-menu__item"
                :is-button="true"
                @click="signIn"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Favorites")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="/img/account-favorite.svg"
                      alt="favorites button"
                    />
                  </div>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                class="modal-menu__item"
                :route-name="'comparison'"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title"
                    >{{ `${_T("@Comparison")}` }}
                  </span>
                </template>
                <template #image>
                  <div class="cart-img">
                    <img
                      src="~~/assets/icons/comparison-mobile-menu.svg"
                      alt="comparison button"
                    />
                    <div
                      v-if="comparisonProducts.length > 0"
                      class="cart-number"
                    >
                      <span class="cart-count">{{
                        comparisonProducts.length
                      }}</span>
                    </div>
                  </div>
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :href="`tel:${_T('@Call center number')}`"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Sales department") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Call center number") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1181 14.702L13.9998 15.5C11.2181 14.1038 9.49985 12.5 8.49985 10L9.2698 5.8699L7.81436 2L4.06344 2C2.9359 2 2.04799 2.93178 2.21639 4.04668C2.63679 6.83 3.87638 11.8765 7.49985 15.5C11.305 19.3052 16.7856 20.9564 19.8019 21.6127C20.9666 21.8662 21.9998 20.9575 21.9998 19.7655L21.9998 16.1812L18.1181 14.702Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :href="`tel:${_T('@Technical Specialist Dmitriy tel.num')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical support") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical Specialist Dmitriy tel.num") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 15.4384V13.5616C2 12.6438 2.62459 11.8439 3.51493 11.6213L5.25448 11.1864C5.63317 11.0917 6 11.3781 6 11.7685V17.2315C6 17.6219 5.63317 17.9083 5.25448 17.8136L3.51493 17.3787C2.62459 17.1561 2 16.3562 2 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M22 15.4384V13.5616C22 12.6438 21.3754 11.8439 20.4851 11.6213L18.7455 11.1864C18.3668 11.0917 18 11.3781 18 11.7685V17.2315C18 17.6219 18.3668 17.9083 18.7455 17.8136L20.4851 17.3787C21.3754 17.1561 22 16.3562 22 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M20 18V18.5C20 19.6046 19.1046 20.5 18 20.5H14.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M13.5 22H10.5C9.67157 22 9 21.3284 9 20.5C9 19.6716 9.67157 19 10.5 19H13.5C14.3284 19 15 19.6716 15 20.5C15 21.3284 14.3284 22 13.5 22Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
              <ModalMenuItem
                :href="`tel:${_T('@Technical Specialist 2 tel.num')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical support") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Technical Specialist 2 tel.num") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 11C20 6.58172 16.4183 3 12 3C7.58172 3 4 6.58172 4 11"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 15.4384V13.5616C2 12.6438 2.62459 11.8439 3.51493 11.6213L5.25448 11.1864C5.63317 11.0917 6 11.3781 6 11.7685V17.2315C6 17.6219 5.63317 17.9083 5.25448 17.8136L3.51493 17.3787C2.62459 17.1561 2 16.3562 2 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M22 15.4384V13.5616C22 12.6438 21.3754 11.8439 20.4851 11.6213L18.7455 11.1864C18.3668 11.0917 18 11.3781 18 11.7685V17.2315C18 17.6219 18.3668 17.9083 18.7455 17.8136L20.4851 17.3787C21.3754 17.1561 22 16.3562 22 15.4384Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M20 18V18.5C20 19.6046 19.1046 20.5 18 20.5H14.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M13.5 22H10.5C9.67157 22 9 21.3284 9 20.5C9 19.6716 9.67157 19 10.5 19H13.5C14.3284 19 15 19.6716 15 20.5C15 21.3284 14.3284 22 13.5 22Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :href="`tel:${_T('@Service phone')}`"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Service center") }}:
                  </span>
                  <span class="modal-menu__item-title">
                    {{ _T("@Service phone") }}
                  </span>
                </template>
                <template #image>
                  <img src="/img/menu-service.svg" alt="service center phone" />
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <section class="modal-menu__item-dropdown">
                <div
                  class="modal-menu__menu-header"
                  :class="{ active: isOpenList.aboutCompany }"
                  @click="onOpenList('aboutCompany')"
                >
                  <ModalMenuItem
                    class="modal-menu__item"
                    :is-button="true"
                    @click="closeModal('dropdown')"
                  >
                    <template #title>
                      <span class="modal-menu__item-title">
                        {{ _T("@About company") }}
                      </span>
                    </template>
                    <template #image>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.16117 10.1918C6.49127 13.6211 10.6586 15.9008 14.8002 16.5178C14.2896 13.9006 14.0316 11.2407 14.0298 8.5745C12.8244 8.24184 10.7503 7.77364 10.3009 7.68226C9.80219 7.58192 9.29463 7.53149 8.78582 7.53175C7.87099 7.54071 5.96586 8.16062 6.16117 10.1918ZM23.2877 12.3383C21.2462 11.1997 19.1091 10.2405 16.9007 9.47156C16.7941 11.9148 16.2768 14.3228 15.3706 16.5954C19.5205 17.0564 23.4662 15.8178 23.2877 12.3353V12.3383ZM26.3605 13.1392C26.3605 15.7379 25.5875 18.2782 24.1392 20.4389C22.691 22.5996 20.6325 24.2837 18.2241 25.2782C15.8157 26.2727 13.1656 26.5329 10.6089 26.0259C8.05219 25.5189 5.70371 24.2675 3.86042 22.43C2.01713 20.5924 0.761828 18.2513 0.253266 15.7025C-0.255297 13.1538 0.00569555 10.5119 1.00328 8.11105C2.00086 5.71018 3.69023 3.65812 5.85771 2.21437C8.02518 0.77062 10.5734 0 13.1802 0C16.6758 0.000158392 20.0281 1.38455 22.4997 3.8486C24.9714 6.31266 26.3599 9.65455 26.3599 13.1392H26.3605ZM2.86132 7.21221C3.37634 7.00605 3.9241 6.89302 4.4789 6.87838C5.43427 6.94367 6.4008 7.04421 7.37853 7.17998C6.94998 7.1549 6.52461 7.13796 6.10244 7.12921C4.97254 7.1949 2.66004 8.10569 3.03387 10.6858C4.30157 19.4299 24.3619 21.3165 24.3529 12.9099C24.6009 13.0512 24.8466 13.1945 25.0898 13.3398C25.0898 13.273 25.0898 13.2061 25.0898 13.1392C25.089 10.5254 24.2229 7.98502 22.626 5.91199C21.0291 3.83896 18.7906 2.3492 16.2578 1.67378C13.7249 0.998369 11.0393 1.17507 8.6174 2.17646C6.19555 3.17785 4.17286 4.94794 2.86311 7.21221H2.86132ZM23.7909 18.5304C18.423 22.5319 4.54599 19.876 1.26831 13.1553C1.2715 15.8211 2.17451 18.4083 3.83197 20.5002C5.48943 22.5922 7.8049 24.0673 10.4055 24.6879C13.0062 25.3086 15.7406 25.0387 18.1687 23.9217C20.5968 22.8048 22.5771 20.9058 23.7909 18.5304Z"
                          fill="#2B2B2B"
                        />
                      </svg>
                    </template>
                  </ModalMenuItem>
                  <div class="modal-menu__item-arrow">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 6L15 12L9 18"
                        stroke="#009b3e"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  v-if="isOpenList.aboutCompany"
                  class="modal-menu__menu-body"
                >
                  <ul class="modal-menu__dropdown-list">
                    <li
                      v-for="(item, index) in aboutCompany"
                      :key="index"
                      class="modal-menu__dropdown-category"
                    >
                      <MyLink :name="item.routeName" @click="closeModal">
                        {{ _T(item.title) }}
                      </MyLink>
                    </li>
                  </ul>
                </div>
              </section>

              <ModalMenuItem
                :route-name="'installation-work'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{
                    _T("@Our objects")
                  }}</span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.8691 3.08984H16.6391C18.3991 3.08984 19.8291 4.50984 19.8291 6.27984V13.6498"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.92 3.08984H6.19C4.43 3.08984 3 4.50984 3 6.27984V19.1398C3 20.9098 4.43 22.3298 6.19 22.3298H11.92"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.83016 1H12.9502C13.4602 1 13.8702 1.41 13.8702 1.92V5.19C13.8702 6.56 12.7602 7.67 11.3902 7.67C10.0202 7.67 8.91016 6.56 8.91016 5.19V1.92C8.91016 1.41 9.32016 1 9.83016 1Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.91016 10.9609H16.2402"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.9707 10.9609H8.2707"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.91016 14.2891H16.2402"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.9707 14.2891H8.2707"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.8803 15.5898L17.2503 19.9398C16.5903 20.7298 16.2603 21.1298 15.8203 21.1498C15.3803 21.1698 15.0103 20.8098 14.2803 20.0798L12.8203 18.6198"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :route-name="'contacts'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Contacts") }}
                  </span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 6.6C22 9.69279 16.5 15 16.5 15C16.5 15 11 9.69279 11 6.6C11 3.50721 13.4624 1 16.5 1C19.5376 1 22 3.50721 22 6.6Z"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                    />
                    <path
                      d="M16.5 7C16.7761 7 17 6.77614 17 6.5C17 6.22386 16.7761 6 16.5 6C16.2239 6 16 6.22386 16 6.5C16 6.77614 16.2239 7 16.5 7Z"
                      fill="#2B2B2B"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.0881 17.5265L10.9988 18.125C8.91207 17.0779 7.6231 15.875 6.87294 14L7.45053 10.9024L6.35873 8L4.05955 8C2.932 8 2.04472 8.93144 2.24287 10.0414C2.62713 12.1941 3.61211 15.6148 6.12279 18.125C8.76171 20.7634 12.4747 22.0211 14.8083 22.5849C15.9669 22.8649 17 21.9575 17 20.7655L17 18.6359L14.0881 17.5265Z"
                      fill="white"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>

              <ModalMenuItem
                :route-name="'contacts'"
                :hash="'#shops'"
                class="modal-menu__item"
                @click="closeModal()"
              >
                <template #title>
                  <span class="modal-menu__item-title">
                    {{ _T("@Our stores") }}
                  </span>
                </template>
                <template #image>
                  <img src="~~/assets/icons/stores-icon.svg" alt="stores" />
                </template>
              </ModalMenuItem>
            </section>

            <section class="modal-menu__section">
              <section class="modal-menu__item-dropdown">
                <div
                  class="modal-menu__menu-header"
                  :class="{ active: isOpenList.partnership }"
                  @click="onOpenList('partnership')"
                >
                  <ModalMenuItem
                    class="modal-menu__item"
                    :is-button="true"
                    @click="closeModal('dropdown')"
                  >
                    <template #title>
                      <span class="modal-menu__item-title">
                        {{ _T("@Cooperation") }}
                      </span>
                    </template>
                    <template #image>
                      <img
                        src="~~/assets/icons/nav-cooperation-icon.svg"
                        alt="cooperation"
                      />
                    </template>
                  </ModalMenuItem>
                  <div class="modal-menu__item-arrow">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 6L15 12L9 18"
                        stroke="#009b3e"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div
                  v-if="isOpenList.partnership"
                  class="modal-menu__menu-body"
                >
                  <ul class="modal-menu__dropdown-list">
                    <li
                      v-for="(item, index) in partnership"
                      :key="index"
                      class="modal-menu__dropdown-category"
                    >
                      <MyLink :name="item.routeName" @click="closeModal">
                        {{ _T(item.title) }}
                      </MyLink>
                    </li>
                  </ul>
                </div>
              </section>
              <section class="modal-menu__item-dropdown">
                <div
                  class="modal-menu__menu-header"
                  :class="{ active: isOpenList.services }"
                  @click="onOpenList('services')"
                >
                  <ModalMenuItem
                    class="modal-menu__item"
                    :is-button="true"
                    @click="closeModal('dropdown')"
                  >
                    <template #title>
                      <span class="modal-menu__item-title">
                        {{ _T("@Services") }}
                      </span>
                    </template>
                    <template #image>
                      <img
                        src="~~/assets/icons/nav-services-icon.svg"
                        alt="cooperation"
                      />
                    </template>
                  </ModalMenuItem>
                  <div class="modal-menu__item-arrow">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 6L15 12L9 18"
                        stroke="#009b3e"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div v-if="isOpenList.services" class="modal-menu__menu-body">
                  <ul class="modal-menu__dropdown-list">
                    <li
                      v-for="(item, index) in services"
                      :key="index"
                      class="modal-menu__dropdown-category"
                    >
                      <MyLink :name="item.routeName" @click="closeModal">
                        {{ _T(item.title) }}
                      </MyLink>
                    </li>
                  </ul>
                </div>
              </section>
            </section>

            <section class="modal-menu__section">
              <ModalMenuItem
                :route-name="'blog'"
                class="modal-menu__item"
                @click="closeModal"
              >
                <template #title>
                  <span class="modal-menu__item-title">{{ _T("@Blog") }}</span>
                </template>
                <template #image>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 8L4 6L16 6V8"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 6L10 18M10 18H12M10 18H8"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 13.5L14 12L20 12V13.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 12V18M17 18H15.5M17 18H18.5"
                      stroke="#2B2B2B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </template>
              </ModalMenuItem>
            </section>
          </div>
        </main>
        <main class="modal-menu__catalog">
          <div class="modal-menu__catalog-wrapper">
            <ButtonBack @click="onCollapseCategories" />
            <ClientOnly>
              <ul class="modal-menu__catalog-menu">
                <li v-if="categories[0]?.parent" class="catalog-item">
                  <MyLink
                    class="catalog-item__wrapper"
                    name="category"
                    :params="{
                      categorySlug: categories[0].parent.slug,
                    }"
                    @click="closeModal()"
                  >
                    <span class="catalog-item__title">
                      {{ _T("@All goods") }}
                    </span>
                  </MyLink>
                </li>
                <MenuCatalogItem
                  v-for="category in categories"
                  :key="category.id"
                  :category="category"
                  @click="onExpandCategory(category)"
                />
              </ul>
            </ClientOnly>
          </div>
        </main>
        <footer class="modal-menu__footer">
          <p class="modal-menu__social-network-title">
            {{ _T("@We on social media") }}:
          </p>
          <div class="modal-menu__social-networks">
            <a
              :href="socials.instagram"
              class="modal-menu__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16Z"
                  stroke="#009b3e"
                  stroke-width="1.5"
                />
                <path
                  d="M17.5 6.51L17.51 6.49889"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <a
              :href="socials.youtube"
              class="modal-menu__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 12L10.5 14V10L14 12Z"
                  fill="#009b3e"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2 12.708V11.2929C2 8.39754 2 6.94988 2.90549 6.01841C3.81099 5.08694 5.23656 5.04662 8.08769 4.96598C9.43873 4.92777 10.8188 4.90039 12 4.90039C13.1812 4.90039 14.5613 4.92777 15.9123 4.96598C18.7634 5.04662 20.189 5.08694 21.0945 6.01841C22 6.94988 22 8.39754 22 11.2929V12.708C22 15.6033 22 17.0509 21.0945 17.9824C20.189 18.9139 18.7635 18.9542 15.9124 19.0349C14.5613 19.0731 13.1812 19.1005 12 19.1005C10.8188 19.1005 9.43867 19.0731 8.0876 19.0349C5.23651 18.9542 3.81097 18.9139 2.90548 17.9824C2 17.0509 2 15.6033 2 12.708Z"
                  stroke="#009b3e"
                  stroke-width="1.5"
                />
              </svg>
            </a>

            <a
              :href="socials.facebook"
              class="modal-menu__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 8V16C21 18.7614 18.7614 21 16 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8Z"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 21C11 18 11 15 11 12C11 9.8125 11.5 8 15 8"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9 13H11H15"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>

            <a
              :href="socials.telegram"
              class="modal-menu__social-item"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 8L5 12.5L9.5 14M18 8L9.5 14M18 8L14 18.5L9.5 14"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#009b3e"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<script setup>
import MenuCatalogItem from "~~/modules/header/sections/MenuCatalogItem.vue";
import ButtonLanguage from "~~/modules/header/components/UI/ButtonLanguage.vue";
import ModalMenuItem from "~~/modules/header/components/UI/ModalMenuItem.vue";
import ButtonBack from "~~/modules/header/components/UI/ButtonBack.vue";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import { useHeaderStore } from "~~/store/headerStore";
import { useCategoriesStore } from "~~/store/categoriesStore";
import { addScopedEventListener } from "~~/utils/eventListener";
import { useCartStore } from "~~/store/cartStore";
import { useComparisonStore } from "~/store/comparisonStore";
import { useUserStore } from "~/store/userStore";
import {
  accountModal,
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useFavoriteProductStore } from "~/store/favoriteProductStore";
import socials from "~~/utils/socials";
import {
  discontinuedCategoryCode,
  promotionCategoryCode,
} from "~/config/category.config";
import { isPromotionEnded } from "~/utils/valueMatched";

const categoriesStore = useCategoriesStore();
const {
  getCategoriesByParent: parentCategories,
  getChildren: children,
  getTopLevelCategories: topLevelCategories,
} = storeToRefs(categoriesStore);

const cartStore = useCartStore();
const { getProductsQuantity: cartQuantity } = storeToRefs(cartStore);

const comparisonStore = useComparisonStore();
const { getProducts: comparisonProducts } = storeToRefs(comparisonStore);

const favoriteProductStore = useFavoriteProductStore();
const { getProductsLength: favoritesProducts } =
  storeToRefs(favoriteProductStore);

const userStore = useUserStore();
const modalStore = useModalStore();

const mobileCatalog = ref(false);

const header = useHeaderStore();
const menuItems = header.getModalMenu;

const activeMenu = header.activeModal;
const activeCatalog = header.activeCatalog;

const categories = ref(null);

function closeModal(type) {
  if (type !== "dropdown") {
    header.activeModal(false);
    mobileCatalog.value = false;
  } else {
    header.activeModal(true);
  }
}

function showCart() {
  modalStore.toggleModal(cartModal);
  closeModal();
}

function openCatalog() {
  if (window.innerWidth > 1024) {
    activeMenu(false);
    activeCatalog(true);
    document.body.style.overflow = "hidden";
  } else {
    mobileCatalog.value = true;
  }
}

function defaultModal() {
  mobileCatalog.value = window.innerWidth <= 1024;
}

function onExpandCategory(category) {
  if (0 === children.value(category).length) {
    mobileCatalog.value = false;

    return (categories.value = topLevelCategories.value.filter(
      (category) => category.code !== discontinuedCategoryCode,
    ));
  }

  if (promotionCategoryCode !== category.code) {
    return (categories.value = children.value(category));
  }

  return (categories.value = children
    .value(category)
    .filter((child) => !isPromotionEnded(child))
    .sort((a, b) => b.promotion.period.endAt - a.promotion.period.endAt));
}

function onCollapseCategories() {
  if (null === categories.value[0]?.parent) {
    mobileCatalog.value = false;
  }

  categories.value = parentCategories
    .value(categories.value[0]?.parent)
    .filter((category) => category.code !== discontinuedCategoryCode);
}

function signIn() {
  modalStore.toggleModal(accountModal, { component: "signIn" });
  closeModal();
}

function signUp() {
  modalStore.toggleModal(accountModal, { component: "signUp" });
  closeModal();
}

onMounted(() => {
  categories.value = topLevelCategories.value.filter(
    (category) => category.code !== discontinuedCategoryCode,
  );
  addScopedEventListener(window, "resize", defaultModal);
});

const isOpenList = reactive({
  aboutCompany: false,
  partnership: false,
  services: false,
});

function onOpenList(listName) {
  isOpenList[listName] = !isOpenList[listName];
}

const aboutCompany = [
  { title: "@About company", routeName: "about-company" },
  { title: "@Delivery and payment", routeName: "delivery" },
];

const partnership = [
  { title: "@For installers", routeName: "installation-training" },
  { title: "@Wholesale clients", routeName: "opt" },
  { title: "@Installation companies", routeName: "cooperation" },
];

const services = [
  { title: "@Flat", routeName: "apartment" },
  { title: "@House", routeName: "house" },
  { title: "@Office", routeName: "office" },
  { title: "@Shop", routeName: "shop" },
  { title: "@Warehouse", routeName: "warehouse" },
  { title: "@Production", routeName: "enterprises" },
];
</script>
