<template>
  <li
    @mouseenter="visible = true"
    @mouseleave="visible = false"
    @click="closeCatalog"
  >
    <MyLink
      class="modal-catalog-item"
      name="category"
      :params="{ categorySlug: category.slug }"
      @click="inactiveCatalog"
    >
      <div class="modal-catalog-item__wrapper">
        <div
          v-if="category.getImageUrl('menuImage', false)"
          class="modal-catalog-item__image"
        >
          <img
            :src="category.getImageUrl('menuImage')"
            :alt="category.getName()"
          />
        </div>
        <span class="modal-catalog-item__title">
          {{ category.getName() }}
        </span>
      </div>
      <div
        v-if="children(category).length > 0"
        class="modal-catalog-item__image-arrow"
      >
        <img src="~~/assets/icons/arrow-right.svg" alt="navigation arrow" />
      </div>
    </MyLink>
    <template v-if="children(category).length > 0">
      <ul v-show="visible" class="modal-catalog-item__new-list">
        <ModalCatalogItem
          v-for="child in getFilteredChildren(category)"
          :key="child.id"
          :category="child"
        />
      </ul>
    </template>
  </li>
</template>

<script setup>
import { useCategoriesStore } from "~/store/categoriesStore";
import { useHeaderStore } from "~~/store/headerStore";
import { promotionCategoryCode } from "~/config/category.config";
import { isPromotionEnded } from "~/utils/valueMatched";

const props = defineProps({
  category: {},
});

const header = useHeaderStore();
const activeCatalog = header.activeCatalog;

const categoriesStore = useCategoriesStore();
const { getChildren: children } = storeToRefs(categoriesStore);

const visible = ref(false);

const getFilteredChildren = (category) => {
  if (promotionCategoryCode !== category.code) {
    return children.value(category);
  }

  return children
    .value(category)
    .filter((child) => !isPromotionEnded(child))
    .sort((a, b) => b.promotion.period.endAt - a.promotion.period.endAt);
};

function inactiveCatalog() {
  activeCatalog(false);
}

function closeCatalog() {
  activeCatalog(false);
  document.body.style.overflow = "auto";
}
</script>
